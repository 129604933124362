import { Routes,Route } from "react-router-dom"
import Login from "./Component/Login/Login"
import Main from "./Main"
import Dashboard from "./Component/Dashboard/Dashboard"
import Users from "./Component/Users/User_main/Users"
import Fund_manager from "./Component/Fund_manager/Fund_manager"
import Info_fund_manager from "./Component/Users/Info_Fund_manager/U_fund_manager/U_Fund_manager";
import Investor from "./Component/Investor/Investor"
import Report from "./Component/Report/Report"
import Setting from "./Component/Setting/Setting"
import Info_Investor from "./Component/Users/Info_Investor/Investor_main/Info_Investor"
import Info from "./Component/Users/Info/Info"


function Routing(){
    return(
        <div>
            <Routes>
                <Route path="/" element={<Login/>}></Route>
                <Route  element={<Main/>}>
                <Route path="/dashboard" element={<Dashboard/>}></Route>
                <Route path="/users" element={<Users/>}></Route>
                <Route path="/fund_manager" element={<Fund_manager/>}></Route>
                <Route path="/users/info/fund_manager" element={<Info_fund_manager/>}></Route>
                <Route path="/investor" element={<Investor/>}></Route>
                <Route path="/report" element={<Report/>}></Route>
                <Route path="/setting" element={<Setting/>}></Route>
                <Route path="/users/info" element={<Info/>}></Route>
                <Route path="/users/info/investor" element={<Info_Investor/>}></Route>
                <Route path="/fund_manager/information_fund_manager" element={<Info_fund_manager/>}></Route>
                <Route path="/investor/information_investor" element={<Info_Investor/>}></Route>
                </Route>
                    
               
            </Routes>
        </div>
    )
}
export default Routing