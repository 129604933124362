import { Col, Row } from "antd"
import "./Setting.css"
import { Collapse } from 'antd';
import Server from "./setting_inside/Server";
import Copy_module from "./setting_inside/Copy_module";
import Profit_sharing from "./setting_inside/Profit_sharing";
import Admin_charge from "./setting_inside/Admin__charge";
import Symbol_selection from"./setting_inside/Symbol_selection";
import Follower_condition from "./setting_inside/Follower_condition";
import Trade_condition from "./setting_inside/Trade_condition";


function Setting() {
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


  const items = [
    {
      key: '1',
      label: <div className="setting_title">
        <h6>SERVER</h6>
        <p>Set Server Condition</p>
      </div>,
      children: <Server />,
    },
    {
      key: '2',
      label: <div className="setting_title">
        <h6>  COPY MODULE</h6>
        <p>Set Copy Module Condition</p>
      </div>,
      children: <Copy_module />,
    },
    ,
    {
      key: '3',
      label: <div className="setting_title">
        <h6>  PROFIT SHARING</h6>
        <p>Set Profit Sharing Condition</p>
      </div>,
      children: <Profit_sharing />,
    },
    ,
    {
      key: '4',
      label: <div className="setting_title">
        <h6>  ADMIN CHARGES</h6>
        <p>Set Admin Charges Condition</p>
      </div>,
      children: <Admin_charge />,
    },
    ,
    {
      key: '5',
      label: <div className="setting_title">
        <h6> SYMBOL SELECTION</h6>
        <p>Set Symbol Selection Condition</p>
      </div>,
      children: <Symbol_selection />,
    },
    ,
    {
      key: "6",
      label: <div className="setting_title">
        <h6>FOLLOWER CONDITION</h6>
        <p>Set Fund Manager Following Condition</p>
      </div>,
      children: <Follower_condition/>,
    },
    ,
    {
      key: '7',
      label: <div className="setting_title">
        <h6>TRADE CONDITION</h6>
        <p>Set One Day Trade Condition</p>
      </div>,
      children:<Trade_condition/>,
    },
  ];
  return (
    <div>

      <div className="setting_main">
        <Collapse items={items} />
      </div>

    </div>
  )
}
export default Setting

