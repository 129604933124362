import "../Setting.css"
import { Col, Row } from "antd";
import { useState } from "react";
import bin from "../../../asset/bin.svg"
function Copy_module() {


    return (
        <div>
            <Row justify={"space-between"}>
                <Col>
                    <div className="setting_title">
                        <h6>COPY MODULE</h6>
                        <p>Set Copy Module Condition</p>
                    </div>
                </Col>
                {/* <Col >
                    <button className="server_btn">CREATE</button>

                </Col> */}

            </Row>
            <Row >
                <Col lg={8} md={24} sm={24}>
                    <Row justify={"space-between"}>
                        <Col  md={12} sm={12} lg={24}>
                            <div className="cm_box">
                            <div className="cm_body">
                                        <p>Direct</p>
                                    
                                        <div className="cm_icon">
                                            <label class="ui-switch">
                                                <input type="checkbox" />
                                                <div class="slider">
                                                    <div class="circle"></div>
                                                </div>
                                            </label>

                                            <img src={bin}></img>
                                        </div>
                                  </div>
                            </div>
                        </Col>
                  
                        <Col md={12} sm={12} lg={24}>
                            <div className="cm_box">

                            <div className="cm_body">
                                        <p>Reverse</p>
                                   
                                        <div className="cm_icon">
                                            <label class="ui-switch">
                                                <input type="checkbox" />
                                                <div class="slider">
                                                    <div class="circle"></div>
                                                </div>
                                            </label>

                                            <img src={bin}></img>
                                        </div>
                                  </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} lg={24}>
                            <div className="cm_box">

                            <div className="cm_body">
                                        <p>Both</p>
                                   
                                        <div className="cm_icon">
                                            <label class="ui-switch">
                                                <input type="checkbox" />
                                                <div class="slider">
                                                    <div class="circle"></div>
                                                </div>
                                            </label>

                                            <img src={bin}></img>
                                        </div>
                                  </div>
                            </div>
                        </Col>
                    </Row>


                </Col>
                <Col lg={16} md={24} sm={24}>


                    <div style={{ display: "flex", alignItems: "end", gap: "20px" }}>
                        <div className="cm_input">

                            <label>New Condition</label>
                            <input type="text" placeholder="Enter New Collection"  ></input>

                        </div>
                        <button className="cm_btn">SAVE</button>
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default Copy_module