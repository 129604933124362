export const Userdata = [
    {
        id:1,
        Day:"Mar",
        Viewers:50
    },
    {
        id:2,
        Day:"Apr",
        Viewers:20
    },
    {
        id:3,
        Day:"May",
        Viewers:10
    },
    {
        id:4,
        Day:"Jun",
        Viewers:22
    },
    {
        id:5,
        Day:"July",
        Viewers:55
    },
    {
        id:6,
        Day:"S",
        Viewers:8
    },
    {
        id:7,
        Day:"Aug",
        Viewers:40
    },
    {
        id:7,
        Day:"Sup",
        Viewers:40
    }
    
    ]