import { Col, Image, Layout, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { LogoutOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import dashbord_logo from "../src/asset/dashbord_logo.svg";
import {
  Link,
  Outlet,
  unstable_HistoryRouter,
  useLocation,
  useMatches,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";

function Main() {
  const location = useLocation();
  const path = location.pathname;
  const [activeButton, setActiveButton] = useState("/dashboard");
  const navigate = useNavigate();

  const btnHandler = (d) => {
    if (activeButton === d.path) {
      setActiveButton(null);
    } else {
      setActiveButton(d.path);
    }
  };

  useEffect(() => {
    const segments = path.split("/"); // Split the path into segments using '/' as the separator

    // Access the first segment
    const firstSegment = segments[1];

    if (segments.length == 3 || segments.length == 4) {
      setActiveButton(`/${firstSegment}`);
    } else {
      setActiveButton(path);
    }
    // if (segments)
  }, [path]);

  const [items, setItems] = useState([
    {
      key: "1",
      label: "DASHBOARD",
      path: "/dashboard",
    },
    {
      key: "2",
      label: "USERS",
      path: "/users",
    },
    {
      key: "3",
      label: "FUND MANGER",
      path: "/fund_manager",
    },
    {
      key: "4",
      label: "INVESTOR",
      path: "/investor",
    },

    {
      key: "5",
      label: "REPORT ",
      path: "/report",
    },

    {
      key: "6",
      label: "SETTING ",
      path: "/setting",
    },
  ]);

  return (
    <div className="main_bg">
      <Layout>
        <Header style={{ background: "white" }}>
          <Row
            style={{
              border: "1px solid #2F2B3D29",
              borderRadius: "5px",
              padding: "0 20px",
              marginTop: "15px",
              alignItems: "center",
            }}
            justify={"space-between"}
          >
            <Col style={{ display: "flex", alignItems: "center" }}>
              {activeButton !== "/dashboard" ? (
                <ArrowLeftOutlined
                  onClick={() => navigate(-1)}
                  style={{ fontSize: "20px" }}
                />
              ) : (
                <img src={dashbord_logo}></img>
              )}
            </Col>
            <Col>
              <div className="header_btn">
                <LogoutOutlined />
                <p className="header_logout">LOGOUT</p>
              </div>
            </Col>
          </Row>
        </Header>
        <div className="main">
          <div className="main_btn">
            {items.map((d) => {
              return (
                <div key={d.id}>
                  <Link to={d.path}>
                    {" "}
                    <button
                      onClick={() => btnHandler(d)}
                      className={`btn ${
                        activeButton === d.path ? "active" : "inactive"
                      }`}
                    >
                      {d.label}
                    </button>
                  </Link>
                </div>
              );
            })}
          </div>
          <Outlet />
        </div>
      </Layout>
    </div>
  );
}
export default Main;
