import { Col, Row, Table } from "antd";
import investor from "./Investor_position.json"

const { Column } = Table;

function Investor_position(){
  return(
    <div>
    <Row>
        <Col style={{ width: "100%", marginTop: "15px" }} >
            <Table dataSource={investor.investor_position} pagination={{
                pageSize: 10
            }}  >

                <Column title="No." dataIndex="no" key="no" />

                <Column title="Position" render={(d) =>
                    <div className="user_positin">
                        <p>{d.position.p1}</p>
                        <p>{d.position.p2}</p>
                    </div>}></Column>

                <Column title="Symbol" dataIndex="symbol" key="symbol" />
                <Column title="Open Time" dataIndex="open_time" key="open_time" />
                <Column title="Volume" dataIndex="vol" key="vol" />
                <Column title="Profit" dataIndex="profit" key="profit" />
                <Column title="Close Time" dataIndex="time" key="time" />



            </Table>
            

        </Col>
    </Row>
</div>
  )
}
export default Investor_position
