import "./Fund_manager.css";
import fund_manager_data from "./Fund_manager.json";
import { Col, Input, Row, DatePicker, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import action from "../../asset/pswd.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

const { Column } = Table;

function Fund_manager() {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(
    fund_manager_data.fund_manager_data
  );

  const onSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);

    // Filter the data based on the search text
    const filtered = fund_manager_data.fund_manager_data.filter((item) =>
      item.gmail.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredData(filtered);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const onChange_to = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <div className="investor_bg">
      <Row className="i_heading" justify={"space-between"}>
        <Col lg="12" style={{ padding: "15px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <p>User List</p>
            <div className="i_input">
              <SearchOutlined />
              <input
                value={searchText}
                onChange={onSearch}
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        </Col>
        <Col lg="12" style={{ padding: "15px", display: "flex", gap: "20px" }}>
          <span>
            From Date: <DatePicker onChange={onChange} />
          </span>
          <span>
            To Date: <DatePicker onChange={onChange_to} />
          </span>
        </Col>
        <Col lg="24" style={{ width: "100%", marginTop: "15px" }}>
          <Table
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
            }}
          >
            <Column title="No." dataIndex="no" key="no" />
            <Column title="ID" dataIndex={"id"} key={"id"}></Column>

            <Column title="Gmail" dataIndex="gmail" key="gmail" />
            <Column title="A/C No" dataIndex="ac" key="ac" />
            <Column title="Registration Date" dataIndex="date" key="date" />

            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(d) => (
                <p className={d == "Active" ? "user_active" : "user_inactive"}>
                  {d}
                </p>
              )}
            />
            <Column
              title="Action"
              dataIndex="action"
              render={() => {
                return (
                  <div>
                    <Link to="/fund_manager/information_fund_manager">
                      <img src={action}></img>
                    </Link>
                  </div>
                );
              }}
            ></Column>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
export default Fund_manager;
