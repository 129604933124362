import { Col, Row } from "antd"
import { Space, Table, Tag, Typography, Modal } from 'antd';
import done from "../../../asset/Vector.svg"
import { useState } from "react";
const { Text } = Typography;

function Server() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
      }

   
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },

        {
            title: 'Connection',
            key: 'conn',
            dataIndex: 'conn',

        },
        {
            title: 'IP Address',
            key: 'ip',
            dataIndex: 'ip',

        }, {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: ""

        }
    ]
    const data = [
        {
            key: '1',
            name: 'MT5 DEMO',
            type: "MT5",
            conn: <img src={done}></img>,
            ip: "105.78.141.234.209",
            status: <Text type="success">Active</Text>
        }

    ];

    return (
        <div className="server_main">
            <Row justify={"space-between"}>
                <Col>
                    <div className="setting_title">
                        <h6>SERVER</h6>
                        <p>Set Server Condition</p>
                    </div>
                </Col>
                <Col >
                    <button className="server_btn" onClick={showModal}>CREATE</button>
                </Col>
            </Row>
            <Modal title="Server Configuration" open={isModalOpen} onCancel={handleCancel} footer={false}>
                <div className="server_modal">
                    <Row justify={"space-between"}>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>Server Name</label>
                            <input type="text" placeholder="MT4 Demo"></input>
                        </Col>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>Server Link</label>
                            <input type="text" placeholder="1c869"></input>
                        </Col>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>Server Type</label>
                            <input type="text" placeholder="Meta Trader4"></input>
                        </Col>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>IP Address</label>
                            <input type="text" placeholder="78.141.234.209"></input>
                        </Col>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>Userame</label>
                            <input type="text" placeholder="105"></input>
                        </Col>
                        <Col className="server_input"lg="8" md="8" sm="24">
                        <label>Password</label>
                            <input type="passwoed" placeholder="*********"></input>
                        </Col>
                    </Row>
                    <Row justify={"center"}>
                        <Col className="server_btn2">
                            <button onClick={handleOk}>SAVE CHANGES</button>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Row >
                <Col className="server_width">
                    <Table columns={columns} dataSource={data} pagination={false} >
                        
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

export default Server