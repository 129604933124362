import { Col, Row, Table, Modal } from "antd";
import action from "../../../asset/pswd.svg";
import performance_fee from "./Performance_fee.json";
import "../Report.css";
import React, { useState } from "react";

const { Column } = Table;

function Performance_fee() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeHandler = () => {
    setIsModalOpen(false)
  }

  return (
    <div className="performance_bg">
      <Row>
        <Col style={{ width: "100%", marginTop: "15px" }}>
          <Table
            dataSource={performance_fee.performance_fee}
            pagination={{
              pageSize: 10,
            }}
          >
            <Column title="No." dataIndex="no" key="no" />
            <Column title="Inv ID" dataIndex="inv_id" key="inv_id" />
            <Column title="Fund ID" dataIndex="fun_id" key="fun_id" />
            <Column title="InvA/C" dataIndex="inv_ac" key="inv_ac" />
            <Column title="FunA/C" dataIndex="fun_ac" key="fun_ac" />{" "}
            <Column title="Amount" dataIndex="amount" key="amount" />
            <Column title="Date" dataIndex="date" key="date" />
            <Column
              title="Action"
              dataIndex="action"
              render={() => {
                return (
                  <div>
                    <img src={action} onClick={showModal}></img>
                  </div>
                );
              }}
            ></Column>
          </Table>
          <div className="report_total">Total : $</div>
        </Col>
      </Row>
     
      <Modal footer={false} open={isModalOpen}onCancel={closeHandler} style={{padding:"0 !important",width:"150px"}} className="modal_main">
        <div className="report_modal">
          <h3>Information</h3>
          <div className="report_body">
            <div className="report_txt">
              <p>No</p>
              <p>01</p>
            </div>
            <div className="report_txt">
              <p>Investor ID</p>
              <p>15</p>
            </div>
            <div className="report_txt">
              <p>Fund Manager ID</p>
              <p>56</p>
            </div>
            <div className="report_txt">
              <p>Investor Account</p>
              <p>532891</p>
            </div>
            <div className="report_txt">
              <p>Amount</p>
              <p>$87.00</p>
            </div>
            <div className="report_txt">
              <p>Date</p>
              <p>22-09-2023</p>
            </div>
            <div className="report_txt">
              <p>Investor Gmail</p>
              <p>shrimalipiyush25@gmail.com</p>
            </div>
            <div className="report_txt">
              <p>Fund Man. Gamil</p>
              <p>shyamkoladiya25@gmail.com</p>
            </div>
            <div className="report_txt">
              <p>MTOrder</p>
              <p>9723597</p>
            </div>
            
          </div>
        </div>
      </Modal>
      
    </div>
  );
}
export default Performance_fee;
