import "./Report.css"
import { Col, Row, Tabs } from 'antd';
import Performance_fee from "./Performance_fee/Performance_fee";
import Platform from "./Platform/Platform";

function Report() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Performance Fee',
      children: <Performance_fee />,
    },
    {
      key: '2',
      label: 'Platform Fee',
      children: <Platform/>,
    }
  ];
  return (
    <div className="report_box">
      <Row>
        <Col style={{ width: "100%", marginTop: "15px" }}>
          <div className="report_tab" >
            <Tabs
              defaultActiveKey="1"
              items={items}
            />
          </div>
        </Col>
      </Row>
    </div>


  )
}
export default Report