import user_data from "./Users.json";
import "./Users.css";
import { Col, Input, Row, DatePicker, Table, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import action from "../../../asset/pswd.svg";
import bin from "../../../asset/delete.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
const { Column } = Table;

function Users() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inactive, setInactive] = useState(false);

  const [userData, SetUserData] = useState(user_data);

  const [record, Setrecord] = useState();

  const showModal = (d) => {
    // setIsModalOpen(true)

    Setrecord(d);

    console.log("pre-data::::", d.status, d);
    if (d.status !== "Active") {
      setIsModalOpen(true);
      const updatedUserData = userData.user_data.filter(
        (user) => user !== record
      );
      SetUserData({ user_data: updatedUserData });
    } else {
      setInactive(true);
    }
  };

  const cancelHandler = () => {
    setIsModalOpen(false);
    setInactive(false);
  };
  const okhandler = () => {
    setIsModalOpen(false);
    setInactive(false);
    const newStatus = record.status === "Active" ? "Inactive" : "Active";

    // Update the status of 'record' in 'userData'
    const updatedUserData = userData.user_data.map((user) => {
      if (user === record) {
        return { ...user, status: newStatus };
      }
      return user;
    });

    SetUserData({ user_data: updatedUserData });
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const onChange_to = (date, dateString) => {
    console.log(date, dateString);
  };

  const showDeleteModal = (record) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this user?",
      onOk() {
        // Delete logic here
        // Remove 'record' from 'userData'
        const updatedUserData = userData.user_data.filter(
          (user) => user !== record
        );
        SetUserData({ user_data: updatedUserData });
      },
      onCancel() {
        // User canceled the delete
      },
    });
  };

  return (
    <div className="investor_bg">
      <Row className="i_heading" justify={"space-between"}>
        <Col lg="12" style={{ padding: "15px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <p>User List</p>
            <div className="i_input">
              <SearchOutlined />
              <input type="text" placeholder="Search" />
            </div>
          </div>
        </Col>
        <Col lg="12" style={{ padding: "15px", display: "flex", gap: "20px" }}>
          <span>
            From Date: <DatePicker onChange={onChange} />
          </span>
          <span>
            To Date: <DatePicker onChange={onChange_to} />
          </span>
        </Col>
        <Col lg="24" style={{ width: "100%", marginTop: "15px" }}>
          <Table
            dataSource={userData.user_data}
            pagination={{
              pageSize: 10,
            }}
          >
            <Column title="No." dataIndex="no" key="no" />

            <Column title="Gmail" dataIndex="gmail" key="gmail" />
            <Column title="Country" dataIndex="country" key="country" />
            <Column title="Registration Date" dataIndex="date" key="date" />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(d) => (
                <p className={d == "Active" ? "user_active" : "user_inactive"}>
                  {d}
                </p>
              )}
            />

            <Column
              title="Action"
              dataIndex="action"
              render={(d, t) => {
                return (
                  <div className="user_action">
                    <Link to="/users/info">
                      <img src={action}></img>
                    </Link>
                    <div onClick={() => showModal(t)}>
                      <img src={bin}></img>
                    </div>
                  </div>
                );
              }}
            ></Column>
          </Table>
        </Col>
      </Row>
      <Modal
        onCancel={cancelHandler}
        open={isModalOpen}
        className="user_modal"
        footer={false}
        closeIcon={false}
      >
        <div className="user_modal_main">
          <h3 className="notify_title">ARE YOU SURE?</h3>
          <p>You Want to Active User!</p>
          <div className="active_btns">
            <button onClick={okhandler}>ACTIVE</button>
            <button onClick={cancelHandler}>CANCEL</button>
          </div>
        </div>
      </Modal>
      <Modal
        onCancel={cancelHandler}
        open={inactive}
        className="user_modal"
        footer={false}
        closeIcon={false}
      >
        <div className="user_modal_main">
          <h3 className="notify_title">ARE YOU SURE?</h3>
          <p>You Want to In Active User!</p>
          <div className="active_btns">
            <button style={{ background: "var(--red)" }} onClick={okhandler}>
              IN ACTIVE
            </button>
            <button onClick={cancelHandler}>CANCEL</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default Users;
