import "../Report.css"
import platform_fee from "./Platform.json"
import {Row,Col,Table} from "antd"
const { Column } = Table;

 
function Platform(){
    return(
        <div>
              <Row>
                <Col style={{ width: "100%", marginTop: "15px" }} >
                    <Table dataSource={platform_fee.platform_fee} pagination={{
                        pageSize: 10
                    }}  >

                        <Column title="No." dataIndex="no" key="no" />

                        
                        <Column title="Fund ID" dataIndex="fun_id" key="fun_id" />
                        <Column title="Fun Gmail" dataIndex="gmail" key="gmail" /> 
                        <Column title="A/C No" dataIndex="ac_no" key="ac_no" />
                      
                        <Column title="Amount" dataIndex="amount" key="amount" />
                        <Column title="Date" dataIndex="date" key="date" />


                    </Table>
                    <div className="report_total">
                 Total : $
                    </div>
                   
                </Col>
            </Row>
        </div>
    )
}
export default Platform