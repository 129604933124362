import "./Info_Investor.css"
import { Col, Collapse, Row, Tabs } from "antd";
import active from "../../../../asset/active.svg"
import action from "../../../../asset/pswd.svg"

import {
    XAxis,
    YAxis,
    Tooltip,
    AreaChart,
    Area,
    ResponsiveContainer,
  } from "recharts";
import Investor_paid from "../Investor_common/Investor_paid/Investor_paid";
import Investor_position from "../Investor_common/Investor_Position/Investor_position";
  const items = [
    {
      key: "1",
      label: "Position",
      children: <Investor_position/>,
    },
    {
      key: "2",
      label: "Paid Fees",
      children:<Investor_paid/>,
    }
  ];

//   graph
const data = [
    {
      name: "Jun",
      uv: 0,
      pv: 0,
    },
    {
      name: "July",
      uv: 30,
      pv: 30,
    },
    {
      name: "Aug",
      uv: 10,
      pv: 10,
    },
    {
      name: "Sep",
      uv: 60,
      pv: 60,
    },
  ];

function Info_Investor(){
    return(
        <div className="u_f_bg">
        <Row justify={"space-between"}>
          <Col style={{ width: "100%" }}>
            <div className="u_f_main">
              <p>INVESTOR</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
            <div className="u_fm_graph">
              <div style={{ width: "100%" }}>
                <p style={{ padding: "10px 0 0 10px ", fontSize: "14px" }}>
                  MONTHLY TRADING PROFIT
                </p>
  
                <ResponsiveContainer width="100%" height={100}>
                 
                  <AreaChart
                    width={730}
                    height={250}
                    data={data}
                    margin={{ top: 10, right: 20, left: 20, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="80%" stopColor="#8884d8" stopOpacity={0} />
                      </linearGradient>
                     
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis hide={true} />
                   
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stroke="#8884d8"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                   
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
            <div className="u_fm_info">
              <h2>$1500</h2>
              <p>TRADING PROFIT</p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
            <div className="  u_fm_graph">
              <h2>$205.25</h2>
              <p> PAID FEES</p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
            <div className="u_fm_info">
              <h2>12</h2>
              <p>COPIED POSITION</p>
            </div>
          </Col>
        </Row>
        <Row>
          
          <Col md={9} sm={12} lg={8}>
            <div className="u_inv_ac">
              <div className="u_fm_ac_heading">
                <p>FUND MANAGER INFORMATION</p>
              </div>
              <div className="u_fm_ac_body">
                <div className="u_fm_grid">
                  <p>No</p>
                  <p>1</p>
                </div>
                
                <div className="u_fm_grid">
                  <p>ID</p>
                  <p>50</p>
                </div>
                
                <div className="u_fm_grid">
                  <p>Gmail</p>
                  <p>shrimalipiyush25@gmail.com</p>
                </div>
                
                <div className="u_fm_grid">
                  <p>Nick Name</p>
                  <p>Piyush</p>
                </div>
                <div className="u_fm_grid ">
                  <p>Registartion Date</p>
                  <p>24-04-2023</p>
                </div>
                
                <div className="u_fm_grid u_fm_grid_border">
                  <p>Trade A/C No</p>
                  <p>253642</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={9} sm={12} lg={8}>
            <div className="u_inv_ac">
              <div className="u_fm_ac_heading">
                <p>INVSETMENT CONDITIONS</p>
              </div>
              <div className="u_fm_ac_body u_fm_prop">
              <div className="u_fm_grid ">
                  <p>Trade Pattern</p>
                  <p>Both</p>
                </div>
                <div className="u_fm_grid ">
                  <p>Copy Direction</p>
                  <p>Direct</p>
                </div>
                <div className="u_fm_grid ">
                  <p>Risk Management</p>
                  <p>
                    <p>Floating Loss-$500</p>
                    <p>Floating Profit-$500</p>
                  </p>
                </div>
                <div className="u_fm_grid ">
                  <p>Symbol Selection</p>
                  <p>All</p>
                </div>

                
                <div className="u_fm_grid u_fm_grid_border">
                  <p>Registartion Date</p>
                  <p>24-04-2023</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={24} lg={8} className="u_inv_main_box">
            <div className="u_inv_box">
                <h6>Fund Manager<br></br>
               Trading Account</h6>
               <div className="u_inv_Active">
                <img src={active}></img>
                <p>253642</p>
                <img src={action}></img>
               </div>
                
            </div>
          </Col>
        </Row>
        <div className="report_box">
          <Row>
            <Col style={{ width: "100%", marginTop: "15px" }}>
              <div className="report_tab">
                <Tabs defaultActiveKey="1" items={items} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )
}
export default Info_Investor