
import { Col, Row, Table } from "antd"
import "./Info.css"
import TextArea from "antd/es/input/TextArea"
import action from "../../../asset/pswd.svg"
import fund_manager from "./Info_fund_manager.json"
import user_investor from "./Info_investor.json"
import undefined_reg from "./Info_undef_reg.json"
import { Link } from "react-router-dom"

const { Column } = Table;

function Info() {
    return (
        <div className="info_bg">
            <Row justify={"space-between"}>
                <Col md={24} lg={8} xl={8} style={{width:"100%"}} sm={24}>
                    <Row >
                        <Col lg={24} md={12} xl={24} sm={12} style={{width:"100%"}}>
                            <div className="i_basic_box">
                                <h4>Basic Information</h4>
                                <div className="basic_detail">
                                    <Row justify={"space-between"}>
                                        <Col>Country : </Col>
                                        <Col>INDIA</Col>
                                    </Row>
                                    <Row justify={"space-between"}>
                                        <Col>Registration Date : </Col>
                                        <Col>24-04-2023</Col>
                                    </Row>
                                    <Row justify={"space-between"}>
                                        <Col>Gmail : </Col>
                                        <Col>Shrimalipiyush@gmail.com</Col>
                                    </Row>
                                    <Row justify={"space-between"}>
                                        <Col>Status : </Col>
                                        <Col>Active</Col>
                                    </Row>
                                </div>
                            </div>

                        </Col>
                        <Col lg={24} md={12} xl={24} sm={12} style={{width:"100%"}}>
                            <div className="i_basic_box">
                                <h4>Send Notification to user</h4>
                                <div className="basic_detail">
                                    <TextArea rows={4} placeholder="Write your Message Here..."></TextArea>
                                    <div className="basic_btns">
                                        <button>SEND</button>
                                        <button>CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={8} xl={8} sm={24} style={{width:"100%"}}>
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={12}>
      
                        <div className="i_basic_info_box">
                        <h4>As a Fund Manager</h4>
                        <div className="user_table">    
                        <Table dataSource={fund_manager.fund_manager} pagination={false}>
                        <Column title="A/C No" dataIndex="ac" key="ac" />
                        <Column title="Leverage" dataIndex="leverage" key="leverage" />
                        <Column title="Status" dataIndex="Status" key="Status" render={(d)=> <p className={d == "Active"? "user_active" : "user_inactive"}>{d}</p>} />
                        <Column title="" dataIndex="action" render={()=>{
                            return(
                               <Link to="/users/info/fund_manager"> <img src={action}></img>
                               </Link>
                            )
                        }} />
                        
                        </Table>

                        </div>
                    </div>
                        </Col>
                        <Col  xl={24} lg={24} md={24} sm={12}>
     
                        <div className="i_basic_info_box ">
                        <h4>Undefined Registartion</h4>
                        <div className="user_table undefined">    
                        <Table dataSource={undefined_reg.undefined_reg} pagination={false}>
                        <Column title="A/C No" dataIndex="ac" key="ac" />
                        <Column title="Leverage" dataIndex="leverage" key="leverage" />
                        
                        
                        </Table>

                        </div>
                    </div> 
                        </Col>
                    </Row>
                      
                        
              
                </Col>
                <Col md={12} lg={8} xl={8} sm={24}style={{width:"100%"}}>
                    <div className="i_basic_info_box">
                        <h4>As a Investor</h4>
                        <div className="user_table">
                        <Table dataSource={user_investor.user_investor} pagination={false}>
                        <Column title="A/C No" dataIndex="ac" key="ac" />
                        <Column title="Leverage" dataIndex="leverage" key="leverage" />
                        <Column title="Status" dataIndex="Status" key="Status" render={(d)=> <p className={d == "Active"? "user_active" : "user_inactive"}>{d}</p>} />
                        <Column title="" dataIndex="action" render={()=>{
                            return(
                                <Link to="/users/info/investor"><img src={action}></img></Link>
                            )
                        }} />
                        
                        </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default Info