import "../Setting.css";
import { Row, Col } from "antd";

function Trade_condition() {
  return (
    <div className="ps_bg">
      <Row justify={"space-between"}>
        <Col>
          <div className="setting_title">
            <h6>TRADE CONDITION</h6>
            <p>Set One Day Trade Condition</p>
          </div>
        </Col>
        {/* <Col >
                <button className="server_btn">
                    EDIT
                </button>

            </Col> */}
      </Row>

      <div className="ps_details">
        <div>
          <label>Follower Condition</label>
          <div className="ac_input">
            <input type="text" placeholder="20"></input>
            <div>MAX</div>
          </div>
        </div>
        <button className="ps_btn">SAVE</button>
      </div>
    </div>
  );
}
export default Trade_condition;
