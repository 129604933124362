import "../Setting.css"
import { Row, Col } from "antd"

function Admin_charge() {
    return (
        <div className="ps_bg">
            <Row justify={"space-between"}>
                <Col>
                    <div className="setting_title">
                        <h6>ADMIN CHARGES</h6>
                        <p>Set Admin Charges Condition</p>
                    </div>
                </Col>
                {/* <Col >
                    <button className="server_btn">
                        EDIT
                    </button>

                </Col> */}

            </Row>

            <div className="ps_details">
                <div>
                    <label>Profit Sharing Condition</label>
                    <div className="ps_input">
                        <input type="text" placeholder="2%"></input>
                        <div>%</div>
                    </div>
                </div>
                <button className="ps_btn">SAVE</button>
            </div>



        </div>
    )
}
export default Admin_charge