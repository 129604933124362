import "../Setting.css"
import { Row,Col } from "antd"

function Profit_sharing(){
    return(
            <div className="ps_bg">
                <Row justify={"space-between"}>
                <Col>
                    <div className="setting_title">
                        <h6>COPY MODULE</h6>
                        <p>Set Copy Module Condition</p>
                    </div>
                </Col>
                {/* <Col >
                    <button className="server_btn">
                        EDIT
                    </button>

                </Col> */}

            </Row>
            
                    <div className="ps_details">
                    <div >
                        <label>Profit Sharing Condition</label>
                       <div className="ps_input">
                       <input type="text" placeholder="20%"></input>
                       <div>%</div>
                       </div>
                    </div>
                    <button className="ps_btn">SAVE</button>
                    </div>
                    
               

        </div>
    )
}
export default Profit_sharing