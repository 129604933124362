import "../Setting.css";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import bin from "../../../asset/bin.svg";

function Symbol_selection() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [symbol, setSymbol] = useState("");

  const [SymbolList, setSymbolList] = useState([
    { code: "USD", name: "United States Dollar" },
    { code: "EUR", name: "Euro" },
    { code: "GBP", name: "British Pound" },
    { code: "JPY", name: "Japanese Yen" },
    { code: "AUD", name: "Australian Dollar" },
    { code: "CAD", name: "Canadian Dollar" },
    { code: "CHF", name: "Swiss Franc" },
    { code: "CNY", name: "Chinese Yuan" },
    { code: "SEK", name: "Swedish Krona" },
    { code: "NZD", name: "New Zealand Dollar" },
    { code: "MXN", name: "Mexican Peso" },
    { code: "SGD", name: "Singapore Dollar" },
    { code: "HKD", name: "Hong Kong Dollar" },
    { code: "NOK", name: "Norwegian Krone" },
    { code: "KRW", name: "South Korean Won" },
    { code: "TRY", name: "Turkish Lira" },
    { code: "RUB", name: "Russian Ruble" },
    { code: "BRL", name: "Brazilian Real" },
    { code: "ZAR", name: "South African Rand" },
    { code: "AED", name: "United Arab Emirates Dirham" },
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const cancelHandler = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    const newSymbol = { code: symbol, name: "" };

    setSymbolList([...SymbolList, newSymbol]);

    setIsModalOpen(false);
  };

  const handleDelet = (code) => {
    // Create a new array by filtering out the object to delete
    const updatedSymbolList = SymbolList.filter(
      (symbol) => symbol.code !== code
    );

    // Update the SymbolList state variable with the new array
    setSymbolList(updatedSymbolList);
  };
  return (
    <div className="ss_bg">
      <Row justify={"space-between"}>
        <Col>
          <div className="setting_title">
            <h6>SYMBOL SELECTION</h6>
            <p>Set Symbol Selection Condition</p>
          </div>
        </Col>
        <Col>
          <button className="server_btn" onClick={showModal}>
            ADD
          </button>
        </Col>
      </Row>
      <Row style={{ paddingTop: "20px" }}>
        <Col lg={24} md={24}>
          <Row justify={"space-between"}>
            {SymbolList.map((item) => {
              return (
                <Col lg={6}>
                  <div className="cm_box">
                    <div className="cm_body">
                      <p>{item.code}/USD</p>

                      <div className="cm_icon">
                        <label class="ui-switch">
                          <input type="checkbox" />
                          <div class="slider">
                            <div class="circle"></div>
                          </div>
                        </label>

                        <img
                          onClick={() => handleDelet(item.code)}
                          src={bin}
                        ></img>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}

            {/* <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>GBP/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>GBP/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>GBP/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>GBP/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>AUD/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>AUD/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>AUD/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>AUD/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>INR/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>INR/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>INR/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="cm_box">
                <div className="cm_body">
                  <p>INR/USD</p>

                  <div className="cm_icon">
                    <label class="ui-switch">
                      <input type="checkbox" />
                      <div class="slider">
                        <div class="circle"></div>
                      </div>
                    </label>

                    <img src={bin}></img>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        onCancel={cancelHandler}
        footer={false}
        onOk={handleOk}
      >
        <div className="server_modal">
          <Row justify={"space-between"}>
            <Col className="server_input" lg="8" md="8" sm="24">
              <label>Set Symbol</label>
              <input
                type="text"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value)}
                placeholder="Set Symbol"
              ></input>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col className="server_btn2">
              <button onClick={handleOk}>SAVE CHANGES</button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}
export default Symbol_selection;
