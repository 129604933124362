import { Col, Collapse, Row, Tabs } from "antd";
import "./U_Fund_manager.css";
import profile from "../../../../asset/profile.svg";
import star from "../../../../asset/star.svg";
import { useState } from "react";
import Fm_Position from "../Info_common/Fm_Position/Fm_Position"
import Fm_Investor from "../Info_common/Fm_Investor/Fm_Investor";
import Fm_Paid_position from "../Info_common/Fm_Paid_position/Fm_Paid_position";

import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

function U_Fund_manager() {
  const [show, setShow] = useState(false);
  const items = [
    {
      key: "1",
      label: "Position",
      children: <Fm_Position/>,
    },
    {
      key: "2",
      label: "Investor",
      children: <Fm_Investor />,
    },
    {
      key: "3",
      label: "Paid Fees",
      children: <Fm_Paid_position />,
    },
  ];

  const data = [
    {
      name: "Jun",
      uv: 0,
      pv: 0,
    },
    {
      name: "July",
      uv: 30,
      pv: 30,
    },
    {
      name: "Aug",
      uv: 10,
      pv: 10,
    },
    {
      name: "Sep",
      uv: 60,
      pv: 60,
    },
  ];

  return (
    <div className="u_f_bg">
      <Row justify={"space-between"}>
        <Col style={{ width: "100%" }}>
          <div className="u_f_main">
            <p>FUND MANAGER</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
          <div className="u_fm_graph">
            <div style={{ width: "100%" }}>
              <p style={{ padding: "10px 0 0 10px ", fontSize: "14px" }}>
                MONTHLY TRADING PROFIT
              </p>

              <ResponsiveContainer width="100%" height={100}>
               
                <AreaChart
                  width={730}
                  height={250}
                  data={data}
                  margin={{ top: 10, right: 20, left: 20, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="80%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                   
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis hide={true} />
                 
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                 
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
          <div className="u_fm_info">
            <h2>18</h2>
            <p>INVESTOR</p>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
          <div className="  u_fm_graph">
            <h2>$205.25</h2>
            <p> CLOSED PROFIT </p>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} style={{ width: "100%" }}>
          <div className="u_fm_info">
            <h2>3</h2>
            <p>LAST MONTH GROWTH</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={24} sm={24} lg={8}   >
          <div className="u_fm_profile_info">
            <div className="u_fm_profile">
              <img src={profile}></img>
              <p>Shrimalipiyush</p>
            </div>
            <div className="u_fm_p_info">
              <div className="u_fm_grid">
                <p>Mini Balance</p>
                <p>$500</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Profit Sharing</p>
                <p>10%</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Profit</p>
                <p>$17,268</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Growth</p>
                <p>$500</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Risk Score</p>
                <p>1/10</p>
              </div>
              
              <div className="u_fm_grid  u_fm_grid_border">
                <p>Star Rating</p>
                <p>
                  <img src={star}></img>
                  <span></span> 5.0
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={12} lg={8} >
          <div className="u_fm_ac">
            <div className="u_fm_ac_heading">
              <p>ACCOUNT INFORMATION</p>
            </div>
            <div className="u_fm_ac_body">
              <div className="u_fm_grid">
                <p>A/C No</p>
                <p>482173</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Platform</p>
                <p>MT5</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Leverage</p>
                <p>500</p>
              </div>
              
              <div className="u_fm_grid">
                <p>Balance</p>
                <p>$500</p>
              </div>
              
              <div className="u_fm_grid u_fm_grid_border">
                <p>Registartion Date</p>
                <p>24-04-2023</p>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={12} lg={8} >
          <div className="u_fm_ac">
            <div className="u_fm_ac_heading">
              <p>ABOUT TRADER</p>
            </div>
            <div className="u_fm_ac_body u_fm_prop">
              <p className="u_fm_trade ">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                <br></br>
                Exercitation veniam consequat sunt nostrud amet.Velit officia
                consequat duis enim velit mollit.
                <br></br>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint.{" "}
                <span className="u_fm_show" onClick={() => setShow(!show)}>
                  Read More...
                </span>
                {/* {
        show && <p style={{paddingTop:"10px"}}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
        </p>
      } */}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <div className="report_box">
        <Row>
          <Col style={{ width: "100%", marginTop: "15px" }}>
            <div className="report_tab">
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default U_Fund_manager;
